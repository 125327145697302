export default {
  tairo: {
    topnav: {
      navigation: {
        enabled: true,
        logo: {
          component: "Logo",
          props: { heightClass: "text-primary-500 h-10" },
        },
        header: {
          component: undefined,
        },
        items: [],
      },
      circularMenu: {
        enabled: false,
        tools: [],
      },
      toolbar: {
        enabled: true,
        showTitle: false,
        tools: [],
      },
      footer: {
        enabled: true,
        logoSeparator: {
          component: "LogoSquare",
          props: { heightClass: "text-primary-500 w-16" },
        },
        logo: {
          component: "Logo",
          props: {
            heightClass:
              "text-muted-300 ltablet:mx-0 mx-auto h-7 transition-all duration-200 lg:mx-0",
          },
        },
        copyright: {
          name: "Inatax",
          to: "https://www.inatax.co.id",
          since: "2021",
        },
        links: [],
      },
    },
  },
}
