import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_m51yvfcbqo from "/vercel/path0/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_I4dbrL2rYz from "/vercel/path0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import ckeditor_client_SbEPQuuVw5 from "/vercel/path0/layers/richTextEditor/plugins/ckeditor.client.ts";
import directives_ak3VYSgns3 from "/vercel/path0/layers/tairo/plugins/directives.ts";
import api_GFfDXud5Cr from "/vercel/path0/plugins/api.ts";
import maska_UHaKf2z1iQ from "/vercel/path0/plugins/maska.ts";
import permission_RYX9gEepuf from "/vercel/path0/plugins/permission.ts";
import vfm_AT9bKzcFx9 from "/vercel/path0/plugins/vfm.ts";
import vue_filters_0r3F66JGby from "/vercel/path0/plugins/vue-filters.ts";
import vue_money3_oQmqY0NulR from "/vercel/path0/plugins/vue-money3.ts";
import vue3_apexchart_client_j1vNdhQp22 from "/vercel/path0/plugins/vue3-apexchart.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  plugin_m51yvfcbqo,
  plugin_I4dbrL2rYz,
  plugin_Jozdw60ZsE,
  ckeditor_client_SbEPQuuVw5,
  directives_ak3VYSgns3,
  api_GFfDXud5Cr,
  maska_UHaKf2z1iQ,
  permission_RYX9gEepuf,
  vfm_AT9bKzcFx9,
  vue_filters_0r3F66JGby,
  vue_money3_oQmqY0NulR,
  vue3_apexchart_client_j1vNdhQp22
]