export default async function useApiFetch(
  path: string,
  options: any = {},
): Promise<any> {
  const { data } = useAuth() //Session User
  let headers: any = {
    accept: "application/json",
  }
  if (data.value) {
    headers["Authorization"] = `Bearer ${data.value?.user?.accessToken}`
  }
  return await $be(`${path}`, {
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  })
}
