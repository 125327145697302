import { default as indexf08yUt5lWhMeta } from "/vercel/path0/layers/accessControl/pages/access-control/permission/index.vue?macro=true";
import { default as index_45indeterminateHOe53V1z3yMeta } from "/vercel/path0/layers/accessControl/pages/access-control/role/[id]/index-indeterminate.vue?macro=true";
import { default as indexrG52A7rWelMeta } from "/vercel/path0/layers/accessControl/pages/access-control/role/[id]/index.vue?macro=true";
import { default as indexPQG1MNo45FMeta } from "/vercel/path0/layers/accessControl/pages/access-control/role/index.vue?macro=true";
import { default as bank_45account9cg7Z5H1aGMeta } from "/vercel/path0/layers/account/pages/account/index/bank-account.vue?macro=true";
import { default as cash_45advanceIVXIplClWBMeta } from "/vercel/path0/layers/account/pages/account/index/cash-advance.vue?macro=true";
import { default as certificationWOjKvILuMOMeta } from "/vercel/path0/layers/account/pages/account/index/certification.vue?macro=true";
import { default as contact_45informationF2vAutluHZMeta } from "/vercel/path0/layers/account/pages/account/index/contact-information.vue?macro=true";
import { default as educationjPCZNdzBWIMeta } from "/vercel/path0/layers/account/pages/account/index/education.vue?macro=true";
import { default as emergency_45contact0WFcTvgaqKMeta } from "/vercel/path0/layers/account/pages/account/index/emergency-contact.vue?macro=true";
import { default as notificationIbe8CphoSPMeta } from "/vercel/path0/layers/account/pages/account/index/notification.vue?macro=true";
import { default as overtime7JCFxoOhRHMeta } from "/vercel/path0/layers/account/pages/account/index/overtime.vue?macro=true";
import { default as photo_45signatureFJIsgkaEugMeta } from "/vercel/path0/layers/account/pages/account/index/photo-signature.vue?macro=true";
import { default as profileuBJyZPx4kjMeta } from "/vercel/path0/layers/account/pages/account/index/profile.vue?macro=true";
import { default as reimbursement2plkKcdyNgMeta } from "/vercel/path0/layers/account/pages/account/index/reimbursement.vue?macro=true";
import { default as report_45overtime05svqZ85MFMeta } from "/vercel/path0/layers/account/pages/account/index/report-overtime.vue?macro=true";
import { default as securityFPSwS4bAZcMeta } from "/vercel/path0/layers/account/pages/account/index/security.vue?macro=true";
import { default as indexBuQYHR7vJQMeta } from "/vercel/path0/layers/account/pages/account/index.vue?macro=true";
import { default as index12a92GGlGFMeta } from "/vercel/path0/layers/application/pages/application/calculator-pph-21/index.vue?macro=true";
import { default as indexMSjtsSeJPwMeta } from "/vercel/path0/layers/application/pages/application/income-tax/index.vue?macro=true";
import { default as indexI1nVule53PMeta } from "/vercel/path0/layers/application/pages/application/tax-slip/[id]/index.vue?macro=true";
import { default as indexi7oFX3fM3DMeta } from "/vercel/path0/layers/application/pages/application/tax-slip/index.vue?macro=true";
import { default as indexw2Wzyhkp7iMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset-location/index.vue?macro=true";
import { default as index5hI9VBR6t2Meta } from "/vercel/path0/layers/asset/pages/asset-management/asset-type/index.vue?macro=true";
import { default as contactsDv3gkgU8j3Meta } from "/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/contacts.vue?macro=true";
import { default as indexfVRgdHmKp1Meta } from "/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/index.vue?macro=true";
import { default as notestoawEd044SMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/notes.vue?macro=true";
import { default as indexanKaxHT07cMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index.vue?macro=true";
import { default as updateQwQlCgOs8lMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset/[id]/update.vue?macro=true";
import { default as createB5bxNBYBXJMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset/create.vue?macro=true";
import { default as indexS8RLstgB6YMeta } from "/vercel/path0/layers/asset/pages/asset-management/asset/index.vue?macro=true";
import { default as indexwyG06AyEubMeta } from "/vercel/path0/layers/asset/pages/asset-management/detail/[code]/index.vue?macro=true";
import { default as accounts3dpEQph0emMeta } from "/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/accounts.vue?macro=true";
import { default as addressadoqM9VBAcMeta } from "/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/address.vue?macro=true";
import { default as indexnr0vs1NnrKMeta } from "/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/index.vue?macro=true";
import { default as indexOvFiGcD0vgMeta } from "/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index.vue?macro=true";
import { default as indexXLvWkUwA1pMeta } from "/vercel/path0/layers/asset/pages/asset-management/vendor/index.vue?macro=true";
import { default as forgot_45passwordDeIu2w1t2ZMeta } from "/vercel/path0/layers/auth/pages/auth/forgot-password.vue?macro=true";
import { default as loginUCT7m3sC9YMeta } from "/vercel/path0/layers/auth/pages/auth/login.vue?macro=true";
import { default as _91token_93ivyoiYm77VMeta } from "/vercel/path0/layers/auth/pages/auth/password/reset/[token].vue?macro=true";
import { default as indexvMoK2qOqdvMeta } from "/vercel/path0/layers/company/pages/companies/index.vue?macro=true";
import { default as bank_45accountEXNVdr3BakMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/bank-account.vue?macro=true";
import { default as clientsG6jTphAVBwMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/clients.vue?macro=true";
import { default as department9HcQY3qZNYMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/department.vue?macro=true";
import { default as employeesUK9mdcbUcdMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/employees.vue?macro=true";
import { default as estimate_45costtQLbp6IkEeMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/estimate-cost.vue?macro=true";
import { default as indextDCCWkIW1JMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/index.vue?macro=true";
import { default as logoiluJaAkvC0Meta } from "/vercel/path0/layers/company/pages/company/[id]/index/logo.vue?macro=true";
import { default as milestone_45templateVpSIYiVUpuMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/milestone-template.vue?macro=true";
import { default as positionGIIq9MH3WyMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/position.vue?macro=true";
import { default as project_45flow4lwG5Yk41mMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/project-flow.vue?macro=true";
import { default as salesJsAOGlJERkMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/sales.vue?macro=true";
import { default as scopes7HYBXCwT8mMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/scopes.vue?macro=true";
import { default as servicesN2qiO3kyMeMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/services.vue?macro=true";
import { default as createByU8AlDHwiMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/setting/create.vue?macro=true";
import { default as index8JGbRnd603Meta } from "/vercel/path0/layers/company/pages/company/[id]/index/setting/index.vue?macro=true";
import { default as update_45_91settingId_93j0n1CmajDpMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/setting/update-[settingId].vue?macro=true";
import { default as tax_45companygbx9XZ57IaMeta } from "/vercel/path0/layers/company/pages/company/[id]/index/tax-company.vue?macro=true";
import { default as indexAohZc6CpxQMeta } from "/vercel/path0/layers/company/pages/company/[id]/index.vue?macro=true";
import { default as updateKI00wZjcC3Meta } from "/vercel/path0/layers/company/pages/company/[id]/update.vue?macro=true";
import { default as createxyOJb6Ot7RMeta } from "/vercel/path0/layers/company/pages/company/create.vue?macro=true";
import { default as estimate_45costjaLWRHGAlmMeta } from "/vercel/path0/layers/company/pages/company/estimate-cost.vue?macro=true";
import { default as indexDA7OnPpouZMeta } from "/vercel/path0/layers/company/pages/company/index.vue?macro=true";
import { default as index8SmOCONulDMeta } from "/vercel/path0/layers/company/pages/company/milestone-template/[id]/index/index.vue?macro=true";
import { default as milestonesU1Uu4ksnoMeta } from "/vercel/path0/layers/company/pages/company/milestone-template/[id]/index/milestone.vue?macro=true";
import { default as indexs3oSmMex9WMeta } from "/vercel/path0/layers/company/pages/company/milestone-template/[id]/index.vue?macro=true";
import { default as createczJaB1bqN8Meta } from "/vercel/path0/layers/company/pages/company/milestone-template/create.vue?macro=true";
import { default as project_45statusuS5WT3koiHMeta } from "/vercel/path0/layers/company/pages/company/project-status.vue?macro=true";
import { default as scopelfmO4l8cuiMeta } from "/vercel/path0/layers/company/pages/company/scope.vue?macro=true";
import { default as indexnxPP46yB34Meta } from "/vercel/path0/layers/company/pages/company/service/[id]/index.vue?macro=true";
import { default as indexItUiGGf2heMeta } from "/vercel/path0/layers/company/pages/company/service/index.vue?macro=true";
import { default as chartsQePS9sYZyyMeta } from "/vercel/path0/pages/dashboard/index/charts.vue?macro=true";
import { default as indexmCugekpX85Meta } from "/vercel/path0/pages/dashboard/index/index.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexVpolLGzDssMeta } from "/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/index.vue?macro=true";
import { default as logsT7bu9yumq3Meta } from "/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/logs.vue?macro=true";
import { default as timesheetFA7YrVayJPMeta } from "/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/timesheet.vue?macro=true";
import { default as indexge0FKpFLW3Meta } from "/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index.vue?macro=true";
import { default as indexUAgWFy4Xe6Meta } from "/vercel/path0/layers/humanResource/pages/direct-assignment/index.vue?macro=true";
import { default as indexHLqq7le5gdMeta } from "/vercel/path0/layers/finance/pages/finance/cash-advance/detail/[id]/index.vue?macro=true";
import { default as _91category_93eoZA90vs5OMeta } from "/vercel/path0/layers/finance/pages/finance/cash-advance/index/[category].vue?macro=true";
import { default as index8lAFQ6KwIVMeta } from "/vercel/path0/layers/finance/pages/finance/cash-advance/index/index.vue?macro=true";
import { default as index0z507TyhwYMeta } from "/vercel/path0/layers/finance/pages/finance/cash-advance/index.vue?macro=true";
import { default as index3HzEnOfrjCMeta } from "/vercel/path0/layers/finance/pages/finance/fund-application/index.vue?macro=true";
import { default as indexEMTgNdpzisMeta } from "/vercel/path0/layers/finance/pages/finance/index/index.vue?macro=true";
import { default as reminderS9YdJeqATlMeta } from "/vercel/path0/layers/finance/pages/finance/index/reminder.vue?macro=true";
import { default as summary4LdrANKwNuMeta } from "/vercel/path0/layers/finance/pages/finance/index/summary.vue?macro=true";
import { default as index3ednhcxqWmMeta } from "/vercel/path0/layers/finance/pages/finance/index.vue?macro=true";
import { default as indexgNxC1b0dV8Meta } from "/vercel/path0/layers/finance/pages/finance/invoice-counter/index.vue?macro=true";
import { default as editYXmJTrvlhWMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/[id]/edit.vue?macro=true";
import { default as indexcWAhmNxT4QMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/index.vue?macro=true";
import { default as logsFonHNv5pDHMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/logs.vue?macro=true";
import { default as reminderv494bm7xshMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/reminder.vue?macro=true";
import { default as indexKyfBvaCfzIMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/[id]/index.vue?macro=true";
import { default as create4wwzOYhGWbMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/create.vue?macro=true";
import { default as indexBA7I0gExGAMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/index/index.vue?macro=true";
import { default as pendingPAUK53skZtMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/index/pending.vue?macro=true";
import { default as settlement1erSJOSYcXMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/index/settlement.vue?macro=true";
import { default as indexLXDv0YBX9dMeta } from "/vercel/path0/layers/finance/pages/finance/invoice/index.vue?macro=true";
import { default as indexQdcF3JoNVLMeta } from "/vercel/path0/layers/finance/pages/finance/payments/index.vue?macro=true";
import { default as indexphe5vEQRxMMeta } from "/vercel/path0/layers/finance/pages/finance/promo/[id]/index.vue?macro=true";
import { default as createLDIDFfzvBTMeta } from "/vercel/path0/layers/finance/pages/finance/promo/create.vue?macro=true";
import { default as index19pkOJ92EVMeta } from "/vercel/path0/layers/finance/pages/finance/promo/index.vue?macro=true";
import { default as files0bfyab98xfMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/files.vue?macro=true";
import { default as indexUIlDCmYAOIMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/index.vue?macro=true";
import { default as logsaAxYZT1awRMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/logs.vue?macro=true";
import { default as indexBP9B9ThPyEMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index.vue?macro=true";
import { default as updateR3wJHl1UoMMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/update.vue?macro=true";
import { default as createKjr7YroBZxMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/create.vue?macro=true";
import { default as indexE41t5gKr6YMeta } from "/vercel/path0/layers/finance/pages/finance/reimbursement/index.vue?macro=true";
import { default as indexql6XdDIoVlMeta } from "/vercel/path0/layers/finance/pages/finance/request-invoice/index.vue?macro=true";
import { default as updateyX5ihekgenMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/announcements/[id]/update.vue?macro=true";
import { default as createmdyXreSARyMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/announcements/create.vue?macro=true";
import { default as indexO5vsmGtV2qMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/announcements/index.vue?macro=true";
import { default as indexSYV6el8lhAMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/appraisal/index.vue?macro=true";
import { default as applicants36S6pQksxkMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/career/[id]/applicants.vue?macro=true";
import { default as updateBaUOLhF30NMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/career/[id]/update.vue?macro=true";
import { default as index2j15al0DhgMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/career/applicant/[id]/index.vue?macro=true";
import { default as createL3exdbZhQaMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/career/create.vue?macro=true";
import { default as indexspgvs4CbCbMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/career/index.vue?macro=true";
import { default as indexFqGv1l2y3ZMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/competency/[id]/index.vue?macro=true";
import { default as indexruT9UIGUxHMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/competency/assessment/[id]/index.vue?macro=true";
import { default as createAd5uZrBunOMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/competency/create.vue?macro=true";
import { default as indexwaSCxLeGlfMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/competency/index.vue?macro=true";
import { default as index6HrNHnhNqcMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/dashboard/index.vue?macro=true";
import { default as index5nAoIgg9ejMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/department/index.vue?macro=true";
import { default as indexZlohc1nDWIMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee-status/index.vue?macro=true";
import { default as access_45controlBJvkZp1o7FMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/access-control.vue?macro=true";
import { default as bank_45accountjpK9TwKKfmMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/bank-account.vue?macro=true";
import { default as certificationfcTUlI48sDMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/certification.vue?macro=true";
import { default as contact_45informationLPnmfnohr3Meta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/contact-information.vue?macro=true";
import { default as educationRtqUuwZanQMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/education.vue?macro=true";
import { default as emergency_45contactmfjBkfD9PIMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/emergency-contact.vue?macro=true";
import { default as indexnZmgLq3NNvMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/index.vue?macro=true";
import { default as indicator_45assessorvXUHASlnPcMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/indicator-assessor.vue?macro=true";
import { default as indicatorv5HTENqbAbMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/indicator.vue?macro=true";
import { default as overtimeurdncOJToqMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/overtime.vue?macro=true";
import { default as photo_45signatureYJQR5XYEXAMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/photo-signature.vue?macro=true";
import { default as positionsKB9KcWnpuQMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/positions.vue?macro=true";
import { default as report_45overtimeZByklzL5glMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/report-overtime.vue?macro=true";
import { default as salaryFaNs3vSz27Meta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/salary.vue?macro=true";
import { default as securityGlkm3TgPvuMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/security.vue?macro=true";
import { default as tax_45insurances56KS9lmzA3Meta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/tax-insurances.vue?macro=true";
import { default as indexaLkSLlcFHAMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index.vue?macro=true";
import { default as createru9AbpQvuNMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/create.vue?macro=true";
import { default as indexWnnO42Er7UMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/employee/index.vue?macro=true";
import { default as indexXPMg7rLWVYMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/goal-calendar/index.vue?macro=true";
import { default as indexeSf3IlNAdHMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/goal-type/index.vue?macro=true";
import { default as indexx1qNl3p9RSMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/holidays/index.vue?macro=true";
import { default as indexkP4drtW0EZMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/indicator/[id]/index.vue?macro=true";
import { default as index8UhqXwqU5ZMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/indicator/index.vue?macro=true";
import { default as index1AhNqAkGEGMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/organizational-chart/index.vue?macro=true";
import { default as hr_45validation3EJw2umTF4Meta } from "/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/hr-validation.vue?macro=true";
import { default as indexC751MaABwiMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/index.vue?macro=true";
import { default as logsfGUoMTngk1Meta } from "/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/logs.vue?macro=true";
import { default as indexYhxhuZBE9FMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index.vue?macro=true";
import { default as indexx3FzkNEtIOMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/overtime/index.vue?macro=true";
import { default as indexhuGs7jirCVMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/position/index.vue?macro=true";
import { default as indexcToXJJRjPMMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/setting-indicator/index.vue?macro=true";
import { default as indexp0CRj9LCQCMeta } from "/vercel/path0/layers/humanResource/pages/human-resource/track-goals/index.vue?macro=true";
import { default as indexJgWkrj4xX2Meta } from "/vercel/path0/layers/inadata/pages/inadata/glosarium/[q]/index.vue?macro=true";
import { default as _91q_93sMIS5DMs6TMeta } from "/vercel/path0/layers/inadata/pages/inadata/glosarium/[q].vue?macro=true";
import { default as indexLfUkhsGkN6Meta } from "/vercel/path0/layers/inadata/pages/inadata/glosarium/index.vue?macro=true";
import { default as indexgqGmKUgTNxMeta } from "/vercel/path0/layers/inadata/pages/inadata/peraturan-pajak/index.vue?macro=true";
import { default as indexbriFdaOj4nMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/chat-stream/[id]/index.vue?macro=true";
import { default as indexvijqgVFnlmMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/chat-stream/index.vue?macro=true";
import { default as indexCGBN1eloewMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/chat/index.vue?macro=true";
import { default as indexQ5lZFULKUiMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/master-system-prompt/index.vue?macro=true";
import { default as indexFcQrcZgs4KMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/score-detail/[id]/index.vue?macro=true";
import { default as indexApM9Eo5HfyMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/score/[id]/index.vue?macro=true";
import { default as indexD0SYZiYjMYMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/score/index.vue?macro=true";
import { default as indexgQwQXolTZAMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/scoring-chat/[id]/index.vue?macro=true";
import { default as indexqpbe8c0wYdMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/scoring-chat/index.vue?macro=true";
import { default as indexTSJ4xsi6okMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/scoring-question/[id]/index.vue?macro=true";
import { default as indexT6IoaMV0CLMeta } from "/vercel/path0/layers/inapintar/pages/inapintar/scoring-question/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as addressbdFUKNkZgeMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/address.vue?macro=true";
import { default as contactspB6bRAFUqKMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/contacts.vue?macro=true";
import { default as filesbkhFv7C0bcMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/files.vue?macro=true";
import { default as financialMEgfUmtUtMMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/financial.vue?macro=true";
import { default as indexx9vOwpr4T8Meta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/index.vue?macro=true";
import { default as invoicesyUxWRSx8YpMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/invoices.vue?macro=true";
import { default as notesSaVHwjzzlGMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/notes.vue?macro=true";
import { default as indexjDErsjNSw7Meta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/projects/[status]/index.vue?macro=true";
import { default as questioner22eBLBARbAMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/questioner.vue?macro=true";
import { default as indexJSjF1neT4kMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index.vue?macro=true";
import { default as index6pfi4qnfKsMeta } from "/vercel/path0/layers/marketing/pages/marketing/[category]/index.vue?macro=true";
import { default as indexLUKsxEPKekMeta } from "/vercel/path0/layers/marketing/pages/marketing/dashboard/index.vue?macro=true";
import { default as clientstDMtTIBuwNMeta } from "/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/clients.vue?macro=true";
import { default as feesc29qk4UsTxMeta } from "/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/fees.vue?macro=true";
import { default as indexDodHIhW9GaMeta } from "/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/index.vue?macro=true";
import { default as index8o59rO26puMeta } from "/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/projects/[status]/index.vue?macro=true";
import { default as indexNdFkmUSxoGMeta } from "/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index.vue?macro=true";
import { default as indextQS8bWQYT4Meta } from "/vercel/path0/layers/marketing/pages/marketing/sales/index.vue?macro=true";
import { default as createkWpFYMg9JVMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/create.vue?macro=true";
import { default as additional7tjKWItQ52Meta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/additional.vue?macro=true";
import { default as email_45marketingV73QiiKB7RMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/email-marketing.vue?macro=true";
import { default as index3r5Ryq1T7pMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/index.vue?macro=true";
import { default as invoices2qtXYpIQisMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/invoices.vue?macro=true";
import { default as participant6hHPngZUbZMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/participant.vue?macro=true";
import { default as index1ovMG8JtOqMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index.vue?macro=true";
import { default as indexAyJjh5izT8Meta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/index.vue?macro=true";
import { default as indexDWXSQaGRpaMeta } from "/vercel/path0/layers/marketing/pages/marketing/seminar/update/[id]/index.vue?macro=true";
import { default as create74TaHLSD5jMeta } from "/vercel/path0/layers/marketing/pages/marketing/speaker-host/create.vue?macro=true";
import { default as indexceZSXhxXCdMeta } from "/vercel/path0/layers/marketing/pages/marketing/speaker-host/index.vue?macro=true";
import { default as indexZ0TZ8VJJDDMeta } from "/vercel/path0/layers/marketing/pages/marketing/speaker-host/update/[id]/index.vue?macro=true";
import { default as createw1N6z5Y4CNMeta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/create.vue?macro=true";
import { default as index3o1szOF9IlMeta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/index.vue?macro=true";
import { default as update6haVN2YFy7Meta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/update.vue?macro=true";
import { default as indexu97gOFua18Meta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index.vue?macro=true";
import { default as index7x9EfFlvAzMeta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/index.vue?macro=true";
import { default as _91id_93A9q7Q3Bv23Meta } from "/vercel/path0/layers/marketing/pages/marketing/template-questioner/list/update/[id].vue?macro=true";
import { default as indexHKe0OUOQn2Meta } from "/vercel/path0/layers/master/pages/master/bank/index.vue?macro=true";
import { default as indexQMxl1ukjMUMeta } from "/vercel/path0/layers/master/pages/master/city/index.vue?macro=true";
import { default as indexgt0Wy2qGHfMeta } from "/vercel/path0/layers/master/pages/master/country/index.vue?macro=true";
import { default as indexH4MnnuS4UYMeta } from "/vercel/path0/layers/master/pages/master/currency/index.vue?macro=true";
import { default as indexN3ZzSP0CezMeta } from "/vercel/path0/layers/master/pages/master/province/index.vue?macro=true";
import { default as indexizRD03NWTHMeta } from "/vercel/path0/layers/master/pages/master/questioner/index.vue?macro=true";
import { default as index04MMBqA3XCMeta } from "/vercel/path0/pages/media-manager/index.vue?macro=true";
import { default as index9tywSo2bTXMeta } from "/vercel/path0/pages/meeting/index.vue?macro=true";
import { default as index8Bc2EDVowKMeta } from "/vercel/path0/pages/monitoring/consultation/index.vue?macro=true";
import { default as indexOlSDo0VBQWMeta } from "/vercel/path0/pages/monitoring/project/index.vue?macro=true";
import { default as indexRqftDruit4Meta } from "/vercel/path0/layers/humanResource/pages/overtime-letter/index.vue?macro=true";
import { default as profileoIx9pgJ2PKMeta } from "/vercel/path0/pages/partner/account/index/profile.vue?macro=true";
import { default as securityhi8GMDJITGMeta } from "/vercel/path0/pages/partner/account/index/security.vue?macro=true";
import { default as indexd2FeQpPfDBMeta } from "/vercel/path0/pages/partner/account/index.vue?macro=true";
import { default as indexRxoObuxBvtMeta } from "/vercel/path0/pages/partner/client/[id]/index/index.vue?macro=true";
import { default as invoiceSYYbq2PHt9Meta } from "/vercel/path0/pages/partner/client/[id]/index/invoice.vue?macro=true";
import { default as projecthRbDqET59rMeta } from "/vercel/path0/pages/partner/client/[id]/index/project.vue?macro=true";
import { default as indexg5IFpVOSyXMeta } from "/vercel/path0/pages/partner/client/[id]/index.vue?macro=true";
import { default as indexnLr3mDcCKVMeta } from "/vercel/path0/pages/partner/client/index.vue?macro=true";
import { default as indexD7Gcj2MXxEMeta } from "/vercel/path0/pages/partner/index.vue?macro=true";
import { default as indexM3aBDkJLgDMeta } from "/vercel/path0/pages/partner/invoice/[id]/index.vue?macro=true";
import { default as indexBtLjKLIQX5Meta } from "/vercel/path0/pages/partner/invoice/index.vue?macro=true";
import { default as indexS1x18hU66oMeta } from "/vercel/path0/pages/partner/project/[id]/index/index.vue?macro=true";
import { default as invoiceKHx6AKgRZ9Meta } from "/vercel/path0/pages/partner/project/[id]/index/invoice.vue?macro=true";
import { default as indexCaEXjKD66SMeta } from "/vercel/path0/pages/partner/project/[id]/index.vue?macro=true";
import { default as indexNFiFlpaguuMeta } from "/vercel/path0/pages/partner/project/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as createTf8mfihtdZMeta } from "/vercel/path0/layers/project/pages/project/create.vue?macro=true";
import { default as assignmentqvrv6tk7YnMeta } from "/vercel/path0/layers/project/pages/project/dashboard/index/assignment.vue?macro=true";
import { default as change_45managerCe02WfBViOMeta } from "/vercel/path0/layers/project/pages/project/dashboard/index/change-manager.vue?macro=true";
import { default as indexPE4m1fzj9gMeta } from "/vercel/path0/layers/project/pages/project/dashboard/index/index.vue?macro=true";
import { default as reviewuwVrrhCxJjMeta } from "/vercel/path0/layers/project/pages/project/dashboard/index/review.vue?macro=true";
import { default as term_45of_45paymentA6X04lxbiLMeta } from "/vercel/path0/layers/project/pages/project/dashboard/index/term-of-payment.vue?macro=true";
import { default as indexa2wRAFx5S7Meta } from "/vercel/path0/layers/project/pages/project/dashboard/index.vue?macro=true";
import { default as filesdgRD5qw44jMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/contract/files.vue?macro=true";
import { default as indexlNUO14QxyRMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/contract/index.vue?macro=true";
import { default as contract6BwRPALHpkMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/contract.vue?macro=true";
import { default as direct_45assignmenth1mci5HfOMMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/direct-assignment.vue?macro=true";
import { default as due_45date_45historyDAZVd7Xv0EMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/due-date-history.vue?macro=true";
import { default as estimate_45costgBxv8xnt29Meta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/estimate-cost.vue?macro=true";
import { default as estimate_45timerMRBu1GlMhcMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/estimate-timer.vue?macro=true";
import { default as external_45issueiYHEFVPiefMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/external-issue.vue?macro=true";
import { default as filesd6VrDRcMkXMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/files.vue?macro=true";
import { default as follow_45upRrgyIBxqIuMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/follow-up.vue?macro=true";
import { default as indexQJLkQNAPgdMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/index.vue?macro=true";
import { default as internal_45issue9vcP4Qh5TCMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/internal-issue.vue?macro=true";
import { default as invoiceJBtLqnyjyzMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/invoice.vue?macro=true";
import { default as logsOhbH9JJqQHMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/logs.vue?macro=true";
import { default as milestoneOTR7aaRqPeMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/milestone.vue?macro=true";
import { default as project_45feehPn953KdijMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/project-fee.vue?macro=true";
import { default as realization3KhQI3T9oaMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/realization.vue?macro=true";
import { default as teamsL296Z893c4Meta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/teams.vue?macro=true";
import { default as term_45of_45paymentcuC1LUNoP3Meta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/term-of-payment.vue?macro=true";
import { default as timesheetMVU5x6JQRkMeta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index/timesheet.vue?macro=true";
import { default as indexZnXS3BRoz1Meta } from "/vercel/path0/layers/project/pages/project/detail/[id]/index.vue?macro=true";
import { default as indexkRmdVAklPlMeta } from "/vercel/path0/layers/project/pages/project/index.vue?macro=true";
import { default as indexqH8afFj32vMeta } from "/vercel/path0/layers/project/pages/project/listing/[category]/index.vue?macro=true";
import { default as index0jpntMicg0Meta } from "/vercel/path0/layers/project/pages/project/recreate/[id]/index.vue?macro=true";
import { default as contractnbMt0oe3LOMeta } from "/vercel/path0/layers/project/pages/project/update/[id]/contract.vue?macro=true";
import { default as indexDrdWyYfjaOMeta } from "/vercel/path0/layers/project/pages/project/update/[id]/index.vue?macro=true";
import { default as asset0C5UUsf4kHMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/asset.vue?macro=true";
import { default as clientbLxxl3p8PjMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/client.vue?macro=true";
import { default as employment_45statusKNI3OoQOM5Meta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/employment-status.vue?macro=true";
import { default as financial_45ratiopXwK8gKCv8Meta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/financial-ratio.vue?macro=true";
import { default as financial_45statementzeeHmRheBJMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/financial-statement.vue?macro=true";
import { default as human_45resourceiYCcPwf78HMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/human-resource.vue?macro=true";
import { default as indexAHs2bNL6TzMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/index.vue?macro=true";
import { default as profit_45loss99J883q6vhMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/profit-loss.vue?macro=true";
import { default as projectm27NoYpftkMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/project.vue?macro=true";
import { default as quotation_45reportSSwB8PkfqmMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/quotation-report.vue?macro=true";
import { default as source_45clientJh8gBhzQJKMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/source-client.vue?macro=true";
import { default as workload2MjWDQdn8pMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index/workload.vue?macro=true";
import { default as indexrqIzlOooyxMeta } from "/vercel/path0/layers/reports/pages/report/monthly/[id]/index.vue?macro=true";
import { default as create4D1FkUYegJMeta } from "/vercel/path0/layers/reports/pages/report/monthly/create.vue?macro=true";
import { default as indexesfodUGKTCMeta } from "/vercel/path0/layers/reports/pages/report/monthly/index.vue?macro=true";
import { default as indexOmGUZ5hgCQMeta } from "/vercel/path0/layers/reports/pages/report/potential-invoice/index.vue?macro=true";
import { default as indexCKjeomZ5jAMeta } from "/vercel/path0/layers/reports/pages/report/progress-project/index.vue?macro=true";
import { default as indexN3f45DUXBMMeta } from "/vercel/path0/layers/reports/pages/report/project-marginal-margin/index.vue?macro=true";
import { default as index6xglOYSlCyMeta } from "/vercel/path0/layers/reports/pages/report/project-per-status/index.vue?macro=true";
import { default as indexL3Y6mPcCThMeta } from "/vercel/path0/layers/reports/pages/report/timer-consultant-per-project/index.vue?macro=true";
import { default as indexDzlF2W9VEUMeta } from "/vercel/path0/layers/reports/pages/report/timer-consultant/index.vue?macro=true";
import { default as index2dZDlis0vIMeta } from "/vercel/path0/layers/reports/pages/report/timesheet/index.vue?macro=true";
import { default as indexr5nZMZEa5EMeta } from "/vercel/path0/pages/restricted/index.vue?macro=true";
import { default as contact_45listuQPIchJaLtMeta } from "/vercel/path0/pages/sleekflow/contact-list.vue?macro=true";
import { default as indexAR77pnRrkYMeta } from "/vercel/path0/pages/support/knowledge-base/index.vue?macro=true";
import { default as indexNMrlSjZCLjMeta } from "/vercel/path0/pages/support/package/index.vue?macro=true";
import { default as index18CoIPDjQjMeta } from "/vercel/path0/pages/support/package/send-document/index.vue?macro=true";
import { default as _91id_933BXoYDybm8Meta } from "/vercel/path0/pages/support/package/track/[id].vue?macro=true";
import { default as indexStGafQtT1bMeta } from "/vercel/path0/pages/support/ticket/index.vue?macro=true";
import { default as indextsk0OLeaOyMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/calculator-pph-21/index.vue?macro=true";
import { default as indexT52VYNhMdPMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/[id]/index.vue?macro=true";
import { default as editzxl4nRH3HeMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/edit.vue?macro=true";
import { default as indexH21zIpWo1iMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/index.vue?macro=true";
import { default as indexxpfXR0RnXQMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/upload-excel/[id]/index.vue?macro=true";
import { default as index8CVO4B1r5ZMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/worksheet/[id]/index.vue?macro=true";
import { default as indexRyxdXqLMeVMeta } from "/vercel/path0/layers/taxCalculation/pages/tax-calculation/worksheet/index.vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
import { default as indexgup4K4QveqMeta } from "/vercel/path0/layers/website/pages/website/expert/[id]/index.vue?macro=true";
import { default as createn7ExI1djO6Meta } from "/vercel/path0/layers/website/pages/website/expert/create.vue?macro=true";
import { default as indexqo5oO9GXm9Meta } from "/vercel/path0/layers/website/pages/website/expert/index.vue?macro=true";
import { default as indexnYWoC4QaN4Meta } from "/vercel/path0/layers/website/pages/website/post/[id]/index.vue?macro=true";
import { default as create3zfnlnSsuJMeta } from "/vercel/path0/layers/website/pages/website/post/create.vue?macro=true";
import { default as indexs2UY5jlpJbMeta } from "/vercel/path0/layers/website/pages/website/post/index.vue?macro=true";
import { default as indexFCiOXLaLnFMeta } from "/vercel/path0/layers/website/pages/website/service/[id]/index.vue?macro=true";
import { default as createvyNYGIPH0dMeta } from "/vercel/path0/layers/website/pages/website/service/create.vue?macro=true";
import { default as index3VfcVhOG55Meta } from "/vercel/path0/layers/website/pages/website/service/index.vue?macro=true";
import { default as form_45checkoutG91ZMzAjsyMeta } from "/vercel/path0/pages/widget/pajakumkm/form-checkout.vue?macro=true";
import { default as indexyjcviuQ6VOMeta } from "/vercel/path0/pages/widget/pajakumkm/pay/[id]/index.vue?macro=true";
export default [
  {
    name: "access-control-permission",
    path: "/access-control/permission",
    meta: indexf08yUt5lWhMeta || {},
    component: () => import("/vercel/path0/layers/accessControl/pages/access-control/permission/index.vue")
  },
  {
    name: "access-control-role-id-index-indeterminate",
    path: "/access-control/role/:id()/index-indeterminate",
    meta: index_45indeterminateHOe53V1z3yMeta || {},
    component: () => import("/vercel/path0/layers/accessControl/pages/access-control/role/[id]/index-indeterminate.vue")
  },
  {
    name: "access-control-role-id",
    path: "/access-control/role/:id()",
    meta: indexrG52A7rWelMeta || {},
    component: () => import("/vercel/path0/layers/accessControl/pages/access-control/role/[id]/index.vue")
  },
  {
    name: "access-control-role",
    path: "/access-control/role",
    meta: indexPQG1MNo45FMeta || {},
    component: () => import("/vercel/path0/layers/accessControl/pages/access-control/role/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/layers/account/pages/account/index.vue"),
    children: [
  {
    name: "account-index-bank-account",
    path: "bank-account",
    component: () => import("/vercel/path0/layers/account/pages/account/index/bank-account.vue")
  },
  {
    name: "account-index-cash-advance",
    path: "cash-advance",
    component: () => import("/vercel/path0/layers/account/pages/account/index/cash-advance.vue")
  },
  {
    name: "account-index-certification",
    path: "certification",
    component: () => import("/vercel/path0/layers/account/pages/account/index/certification.vue")
  },
  {
    name: "account-index-contact-information",
    path: "contact-information",
    component: () => import("/vercel/path0/layers/account/pages/account/index/contact-information.vue")
  },
  {
    name: "account-index-education",
    path: "education",
    component: () => import("/vercel/path0/layers/account/pages/account/index/education.vue")
  },
  {
    name: "account-index-emergency-contact",
    path: "emergency-contact",
    component: () => import("/vercel/path0/layers/account/pages/account/index/emergency-contact.vue")
  },
  {
    name: "account-index-notification",
    path: "notification",
    component: () => import("/vercel/path0/layers/account/pages/account/index/notification.vue")
  },
  {
    name: "account-index-overtime",
    path: "overtime",
    component: () => import("/vercel/path0/layers/account/pages/account/index/overtime.vue")
  },
  {
    name: "account-index-photo-signature",
    path: "photo-signature",
    component: () => import("/vercel/path0/layers/account/pages/account/index/photo-signature.vue")
  },
  {
    name: "account-index-profile",
    path: "profile",
    component: () => import("/vercel/path0/layers/account/pages/account/index/profile.vue")
  },
  {
    name: "account-index-reimbursement",
    path: "reimbursement",
    component: () => import("/vercel/path0/layers/account/pages/account/index/reimbursement.vue")
  },
  {
    name: "account-index-report-overtime",
    path: "report-overtime",
    component: () => import("/vercel/path0/layers/account/pages/account/index/report-overtime.vue")
  },
  {
    name: "account-index-security",
    path: "security",
    component: () => import("/vercel/path0/layers/account/pages/account/index/security.vue")
  }
]
  },
  {
    name: "application-calculator-pph-21",
    path: "/application/calculator-pph-21",
    meta: index12a92GGlGFMeta || {},
    component: () => import("/vercel/path0/layers/application/pages/application/calculator-pph-21/index.vue")
  },
  {
    name: "application-income-tax",
    path: "/application/income-tax",
    meta: indexMSjtsSeJPwMeta || {},
    component: () => import("/vercel/path0/layers/application/pages/application/income-tax/index.vue")
  },
  {
    name: "application-tax-slip-id",
    path: "/application/tax-slip/:id()",
    meta: indexI1nVule53PMeta || {},
    component: () => import("/vercel/path0/layers/application/pages/application/tax-slip/[id]/index.vue")
  },
  {
    name: "application-tax-slip",
    path: "/application/tax-slip",
    component: () => import("/vercel/path0/layers/application/pages/application/tax-slip/index.vue")
  },
  {
    name: "asset-management-asset-location",
    path: "/asset-management/asset-location",
    meta: indexw2Wzyhkp7iMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset-location/index.vue")
  },
  {
    name: "asset-management-asset-type",
    path: "/asset-management/asset-type",
    meta: index5hI9VBR6t2Meta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset-type/index.vue")
  },
  {
    name: indexanKaxHT07cMeta?.name,
    path: "/asset-management/asset/:id()",
    meta: indexanKaxHT07cMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index.vue"),
    children: [
  {
    name: "asset-management-asset-id-index-contacts",
    path: "contacts",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/contacts.vue")
  },
  {
    name: "asset-management-asset-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/index.vue")
  },
  {
    name: "asset-management-asset-id-index-notes",
    path: "notes",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/[id]/index/notes.vue")
  }
]
  },
  {
    name: "asset-management-asset-id-update",
    path: "/asset-management/asset/:id()/update",
    meta: updateQwQlCgOs8lMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/[id]/update.vue")
  },
  {
    name: "asset-management-asset-create",
    path: "/asset-management/asset/create",
    meta: createB5bxNBYBXJMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/create.vue")
  },
  {
    name: "asset-management-asset",
    path: "/asset-management/asset",
    meta: indexS8RLstgB6YMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/asset/index.vue")
  },
  {
    name: "asset-management-detail-code",
    path: "/asset-management/detail/:code()",
    meta: indexwyG06AyEubMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/detail/[code]/index.vue")
  },
  {
    name: indexOvFiGcD0vgMeta?.name,
    path: "/asset-management/vendor/:id()",
    meta: indexOvFiGcD0vgMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index.vue"),
    children: [
  {
    name: "asset-management-vendor-id-index-accounts",
    path: "accounts",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/accounts.vue")
  },
  {
    name: "asset-management-vendor-id-index-address",
    path: "address",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/address.vue")
  },
  {
    name: "asset-management-vendor-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/vendor/[id]/index/index.vue")
  }
]
  },
  {
    name: "asset-management-vendor",
    path: "/asset-management/vendor",
    meta: indexXLvWkUwA1pMeta || {},
    component: () => import("/vercel/path0/layers/asset/pages/asset-management/vendor/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordDeIu2w1t2ZMeta || {},
    component: () => import("/vercel/path0/layers/auth/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginUCT7m3sC9YMeta || {},
    component: () => import("/vercel/path0/layers/auth/pages/auth/login.vue")
  },
  {
    name: "auth-password-reset-token",
    path: "/auth/password/reset/:token()",
    meta: _91token_93ivyoiYm77VMeta || {},
    component: () => import("/vercel/path0/layers/auth/pages/auth/password/reset/[token].vue")
  },
  {
    name: "companies",
    path: "/companies",
    meta: indexvMoK2qOqdvMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/companies/index.vue")
  },
  {
    name: indexAohZc6CpxQMeta?.name,
    path: "/company/:id()",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index.vue"),
    children: [
  {
    name: "company-id-index-bank-account",
    path: "bank-account",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/bank-account.vue")
  },
  {
    name: "company-id-index-clients",
    path: "clients",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/clients.vue")
  },
  {
    name: "company-id-index-department",
    path: "department",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/department.vue")
  },
  {
    name: "company-id-index-employees",
    path: "employees",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/employees.vue")
  },
  {
    name: "company-id-index-estimate-cost",
    path: "estimate-cost",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/estimate-cost.vue")
  },
  {
    name: "company-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/index.vue")
  },
  {
    name: "company-id-index-logo",
    path: "logo",
    meta: logoiluJaAkvC0Meta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/logo.vue")
  },
  {
    name: "company-id-index-milestone-template",
    path: "milestone-template",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/milestone-template.vue")
  },
  {
    name: "company-id-index-position",
    path: "position",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/position.vue")
  },
  {
    name: "company-id-index-project-flow",
    path: "project-flow",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/project-flow.vue")
  },
  {
    name: "company-id-index-sales",
    path: "sales",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/sales.vue")
  },
  {
    name: "company-id-index-scopes",
    path: "scopes",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/scopes.vue")
  },
  {
    name: "company-id-index-services",
    path: "services",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/services.vue")
  },
  {
    name: "company-id-index-setting-create",
    path: "setting/create",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/setting/create.vue")
  },
  {
    name: "company-id-index-setting",
    path: "setting",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/setting/index.vue")
  },
  {
    name: "company-id-index-setting-update-settingId",
    path: "setting/update-:settingId()",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/setting/update-[settingId].vue")
  },
  {
    name: "company-id-index-tax-company",
    path: "tax-company",
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/index/tax-company.vue")
  }
]
  },
  {
    name: "company-id-update",
    path: "/company/:id()/update",
    meta: updateKI00wZjcC3Meta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/[id]/update.vue")
  },
  {
    name: "company-create",
    path: "/company/create",
    meta: createxyOJb6Ot7RMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/create.vue")
  },
  {
    name: "company-estimate-cost",
    path: "/company/estimate-cost",
    meta: estimate_45costjaLWRHGAlmMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/estimate-cost.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/vercel/path0/layers/company/pages/company/index.vue")
  },
  {
    name: indexs3oSmMex9WMeta?.name,
    path: "/company/milestone-template/:id()",
    meta: indexs3oSmMex9WMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/milestone-template/[id]/index.vue"),
    children: [
  {
    name: "company-milestone-template-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/company/pages/company/milestone-template/[id]/index/index.vue")
  },
  {
    name: "company-milestone-template-id-index-milestone",
    path: "milestone",
    component: () => import("/vercel/path0/layers/company/pages/company/milestone-template/[id]/index/milestone.vue")
  }
]
  },
  {
    name: "company-milestone-template-create",
    path: "/company/milestone-template/create",
    meta: createczJaB1bqN8Meta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/milestone-template/create.vue")
  },
  {
    name: "company-project-status",
    path: "/company/project-status",
    meta: project_45statusuS5WT3koiHMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/project-status.vue")
  },
  {
    name: "company-scope",
    path: "/company/scope",
    meta: scopelfmO4l8cuiMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/scope.vue")
  },
  {
    name: "company-service-id",
    path: "/company/service/:id()",
    meta: indexnxPP46yB34Meta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/service/[id]/index.vue")
  },
  {
    name: "company-service",
    path: "/company/service",
    meta: indexItUiGGf2heMeta || {},
    component: () => import("/vercel/path0/layers/company/pages/company/service/index.vue")
  },
  {
    name: indexRIIpWdUktDMeta?.name,
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard/index.vue"),
    children: [
  {
    name: "dashboard-index-charts",
    path: "charts",
    component: () => import("/vercel/path0/pages/dashboard/index/charts.vue")
  },
  {
    name: "dashboard-index",
    path: "",
    component: () => import("/vercel/path0/pages/dashboard/index/index.vue")
  }
]
  },
  {
    name: indexge0FKpFLW3Meta?.name,
    path: "/direct-assignment/:id()",
    meta: indexge0FKpFLW3Meta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index.vue"),
    children: [
  {
    name: "direct-assignment-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/index.vue")
  },
  {
    name: "direct-assignment-id-index-logs",
    path: "logs",
    component: () => import("/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/logs.vue")
  },
  {
    name: "direct-assignment-id-index-timesheet",
    path: "timesheet",
    component: () => import("/vercel/path0/layers/humanResource/pages/direct-assignment/[id]/index/timesheet.vue")
  }
]
  },
  {
    name: "direct-assignment",
    path: "/direct-assignment",
    component: () => import("/vercel/path0/layers/humanResource/pages/direct-assignment/index.vue")
  },
  {
    name: "finance-cash-advance-detail-id",
    path: "/finance/cash-advance/detail/:id()",
    component: () => import("/vercel/path0/layers/finance/pages/finance/cash-advance/detail/[id]/index.vue")
  },
  {
    name: index0z507TyhwYMeta?.name,
    path: "/finance/cash-advance",
    meta: index0z507TyhwYMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/cash-advance/index.vue"),
    children: [
  {
    name: "finance-cash-advance-index-category",
    path: ":category()",
    component: () => import("/vercel/path0/layers/finance/pages/finance/cash-advance/index/[category].vue")
  },
  {
    name: "finance-cash-advance-index",
    path: "",
    component: () => import("/vercel/path0/layers/finance/pages/finance/cash-advance/index/index.vue")
  }
]
  },
  {
    name: "finance-fund-application",
    path: "/finance/fund-application",
    component: () => import("/vercel/path0/layers/finance/pages/finance/fund-application/index.vue")
  },
  {
    name: index3ednhcxqWmMeta?.name,
    path: "/finance",
    component: () => import("/vercel/path0/layers/finance/pages/finance/index.vue"),
    children: [
  {
    name: "finance-index",
    path: "",
    component: () => import("/vercel/path0/layers/finance/pages/finance/index/index.vue")
  },
  {
    name: "finance-index-reminder",
    path: "reminder",
    component: () => import("/vercel/path0/layers/finance/pages/finance/index/reminder.vue")
  },
  {
    name: "finance-index-summary",
    path: "summary",
    component: () => import("/vercel/path0/layers/finance/pages/finance/index/summary.vue")
  }
]
  },
  {
    name: "finance-invoice-counter",
    path: "/finance/invoice-counter",
    meta: indexgNxC1b0dV8Meta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice-counter/index.vue")
  },
  {
    name: "finance-invoice-id-edit",
    path: "/finance/invoice/:id()/edit",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/[id]/edit.vue")
  },
  {
    name: indexKyfBvaCfzIMeta?.name,
    path: "/finance/invoice/:id()",
    meta: indexKyfBvaCfzIMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/[id]/index.vue"),
    children: [
  {
    name: "finance-invoice-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/index.vue")
  },
  {
    name: "finance-invoice-id-index-logs",
    path: "logs",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/logs.vue")
  },
  {
    name: "finance-invoice-id-index-reminder",
    path: "reminder",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/[id]/index/reminder.vue")
  }
]
  },
  {
    name: "finance-invoice-create",
    path: "/finance/invoice/create",
    meta: create4wwzOYhGWbMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/create.vue")
  },
  {
    name: indexLXDv0YBX9dMeta?.name,
    path: "/finance/invoice",
    meta: indexLXDv0YBX9dMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/index.vue"),
    children: [
  {
    name: "finance-invoice-index",
    path: "",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/index/index.vue")
  },
  {
    name: "finance-invoice-index-pending",
    path: "pending",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/index/pending.vue")
  },
  {
    name: "finance-invoice-index-settlement",
    path: "settlement",
    component: () => import("/vercel/path0/layers/finance/pages/finance/invoice/index/settlement.vue")
  }
]
  },
  {
    name: "finance-payments",
    path: "/finance/payments",
    meta: indexQdcF3JoNVLMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/payments/index.vue")
  },
  {
    name: "finance-promo-id",
    path: "/finance/promo/:id()",
    meta: indexphe5vEQRxMMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/promo/[id]/index.vue")
  },
  {
    name: "finance-promo-create",
    path: "/finance/promo/create",
    meta: createLDIDFfzvBTMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/promo/create.vue")
  },
  {
    name: "finance-promo",
    path: "/finance/promo",
    meta: index19pkOJ92EVMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/promo/index.vue")
  },
  {
    name: indexBP9B9ThPyEMeta?.name,
    path: "/finance/reimbursement/:id()",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index.vue"),
    children: [
  {
    name: "finance-reimbursement-id-index-files",
    path: "files",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/files.vue")
  },
  {
    name: "finance-reimbursement-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/index.vue")
  },
  {
    name: "finance-reimbursement-id-index-logs",
    path: "logs",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/index/logs.vue")
  }
]
  },
  {
    name: "finance-reimbursement-id-update",
    path: "/finance/reimbursement/:id()/update",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/[id]/update.vue")
  },
  {
    name: "finance-reimbursement-create",
    path: "/finance/reimbursement/create",
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/create.vue")
  },
  {
    name: "finance-reimbursement",
    path: "/finance/reimbursement",
    meta: indexE41t5gKr6YMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/reimbursement/index.vue")
  },
  {
    name: "finance-request-invoice",
    path: "/finance/request-invoice",
    meta: indexql6XdDIoVlMeta || {},
    component: () => import("/vercel/path0/layers/finance/pages/finance/request-invoice/index.vue")
  },
  {
    name: "human-resource-announcements-id-update",
    path: "/human-resource/announcements/:id()/update",
    meta: updateyX5ihekgenMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/announcements/[id]/update.vue")
  },
  {
    name: "human-resource-announcements-create",
    path: "/human-resource/announcements/create",
    meta: createmdyXreSARyMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/announcements/create.vue")
  },
  {
    name: "human-resource-announcements",
    path: "/human-resource/announcements",
    meta: indexO5vsmGtV2qMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/announcements/index.vue")
  },
  {
    name: "human-resource-appraisal",
    path: "/human-resource/appraisal",
    meta: indexSYV6el8lhAMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/appraisal/index.vue")
  },
  {
    name: "human-resource-career-id-applicants",
    path: "/human-resource/career/:id()/applicants",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/career/[id]/applicants.vue")
  },
  {
    name: "human-resource-career-id-update",
    path: "/human-resource/career/:id()/update",
    meta: updateBaUOLhF30NMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/career/[id]/update.vue")
  },
  {
    name: "human-resource-career-applicant-id",
    path: "/human-resource/career/applicant/:id()",
    meta: index2j15al0DhgMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/career/applicant/[id]/index.vue")
  },
  {
    name: "human-resource-career-create",
    path: "/human-resource/career/create",
    meta: createL3exdbZhQaMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/career/create.vue")
  },
  {
    name: "human-resource-career",
    path: "/human-resource/career",
    meta: indexspgvs4CbCbMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/career/index.vue")
  },
  {
    name: "human-resource-competency-id",
    path: "/human-resource/competency/:id()",
    meta: indexFqGv1l2y3ZMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/competency/[id]/index.vue")
  },
  {
    name: "human-resource-competency-assessment-id",
    path: "/human-resource/competency/assessment/:id()",
    meta: indexruT9UIGUxHMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/competency/assessment/[id]/index.vue")
  },
  {
    name: "human-resource-competency-create",
    path: "/human-resource/competency/create",
    meta: createAd5uZrBunOMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/competency/create.vue")
  },
  {
    name: "human-resource-competency",
    path: "/human-resource/competency",
    meta: indexwaSCxLeGlfMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/competency/index.vue")
  },
  {
    name: "human-resource-dashboard",
    path: "/human-resource/dashboard",
    meta: index6HrNHnhNqcMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/dashboard/index.vue")
  },
  {
    name: "human-resource-department",
    path: "/human-resource/department",
    meta: index5nAoIgg9ejMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/department/index.vue")
  },
  {
    name: "human-resource-employee-status",
    path: "/human-resource/employee-status",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee-status/index.vue")
  },
  {
    name: indexaLkSLlcFHAMeta?.name,
    path: "/human-resource/employee/:id()",
    meta: indexaLkSLlcFHAMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index.vue"),
    children: [
  {
    name: "human-resource-employee-id-index-access-control",
    path: "access-control",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/access-control.vue")
  },
  {
    name: "human-resource-employee-id-index-bank-account",
    path: "bank-account",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/bank-account.vue")
  },
  {
    name: "human-resource-employee-id-index-certification",
    path: "certification",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/certification.vue")
  },
  {
    name: "human-resource-employee-id-index-contact-information",
    path: "contact-information",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/contact-information.vue")
  },
  {
    name: "human-resource-employee-id-index-education",
    path: "education",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/education.vue")
  },
  {
    name: "human-resource-employee-id-index-emergency-contact",
    path: "emergency-contact",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/emergency-contact.vue")
  },
  {
    name: "human-resource-employee-id-index",
    path: "",
    meta: indexnZmgLq3NNvMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/index.vue")
  },
  {
    name: "human-resource-employee-id-index-indicator-assessor",
    path: "indicator-assessor",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/indicator-assessor.vue")
  },
  {
    name: "human-resource-employee-id-index-indicator",
    path: "indicator",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/indicator.vue")
  },
  {
    name: "human-resource-employee-id-index-overtime",
    path: "overtime",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/overtime.vue")
  },
  {
    name: "human-resource-employee-id-index-photo-signature",
    path: "photo-signature",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/photo-signature.vue")
  },
  {
    name: "human-resource-employee-id-index-positions",
    path: "positions",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/positions.vue")
  },
  {
    name: "human-resource-employee-id-index-report-overtime",
    path: "report-overtime",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/report-overtime.vue")
  },
  {
    name: "human-resource-employee-id-index-salary",
    path: "salary",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/salary.vue")
  },
  {
    name: "human-resource-employee-id-index-security",
    path: "security",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/security.vue")
  },
  {
    name: "human-resource-employee-id-index-tax-insurances",
    path: "tax-insurances",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/[id]/index/tax-insurances.vue")
  }
]
  },
  {
    name: "human-resource-employee-create",
    path: "/human-resource/employee/create",
    meta: createru9AbpQvuNMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/create.vue")
  },
  {
    name: "human-resource-employee",
    path: "/human-resource/employee",
    meta: indexWnnO42Er7UMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/employee/index.vue")
  },
  {
    name: "human-resource-goal-calendar",
    path: "/human-resource/goal-calendar",
    meta: indexXPMg7rLWVYMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/goal-calendar/index.vue")
  },
  {
    name: "human-resource-goal-type",
    path: "/human-resource/goal-type",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/goal-type/index.vue")
  },
  {
    name: "human-resource-holidays",
    path: "/human-resource/holidays",
    meta: indexx1qNl3p9RSMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/holidays/index.vue")
  },
  {
    name: "human-resource-indicator-id",
    path: "/human-resource/indicator/:id()",
    meta: indexkP4drtW0EZMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/indicator/[id]/index.vue")
  },
  {
    name: "human-resource-indicator",
    path: "/human-resource/indicator",
    meta: index8UhqXwqU5ZMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/indicator/index.vue")
  },
  {
    name: "human-resource-organizational-chart",
    path: "/human-resource/organizational-chart",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/organizational-chart/index.vue")
  },
  {
    name: indexYhxhuZBE9FMeta?.name,
    path: "/human-resource/overtime/:id()",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index.vue"),
    children: [
  {
    name: "human-resource-overtime-id-index-hr-validation",
    path: "hr-validation",
    meta: hr_45validation3EJw2umTF4Meta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/hr-validation.vue")
  },
  {
    name: "human-resource-overtime-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/index.vue")
  },
  {
    name: "human-resource-overtime-id-index-logs",
    path: "logs",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/overtime/[id]/index/logs.vue")
  }
]
  },
  {
    name: "human-resource-overtime",
    path: "/human-resource/overtime",
    meta: indexx3FzkNEtIOMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/overtime/index.vue")
  },
  {
    name: "human-resource-position",
    path: "/human-resource/position",
    meta: indexhuGs7jirCVMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/position/index.vue")
  },
  {
    name: "human-resource-setting-indicator",
    path: "/human-resource/setting-indicator",
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/setting-indicator/index.vue")
  },
  {
    name: "human-resource-track-goals",
    path: "/human-resource/track-goals",
    meta: indexp0CRj9LCQCMeta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/human-resource/track-goals/index.vue")
  },
  {
    name: _91q_93sMIS5DMs6TMeta?.name,
    path: "/inadata/glosarium/:q()",
    component: () => import("/vercel/path0/layers/inadata/pages/inadata/glosarium/[q].vue"),
    children: [
  {
    name: "inadata-glosarium-q",
    path: "",
    component: () => import("/vercel/path0/layers/inadata/pages/inadata/glosarium/[q]/index.vue")
  }
]
  },
  {
    name: "inadata-glosarium",
    path: "/inadata/glosarium",
    component: () => import("/vercel/path0/layers/inadata/pages/inadata/glosarium/index.vue")
  },
  {
    name: "inadata-peraturan-pajak",
    path: "/inadata/peraturan-pajak",
    component: () => import("/vercel/path0/layers/inadata/pages/inadata/peraturan-pajak/index.vue")
  },
  {
    name: "inapintar-chat-stream-id",
    path: "/inapintar/chat-stream/:id()",
    meta: indexbriFdaOj4nMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/chat-stream/[id]/index.vue")
  },
  {
    name: "inapintar-chat-stream",
    path: "/inapintar/chat-stream",
    meta: indexvijqgVFnlmMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/chat-stream/index.vue")
  },
  {
    name: "inapintar-chat",
    path: "/inapintar/chat",
    meta: indexCGBN1eloewMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/chat/index.vue")
  },
  {
    name: "inapintar-master-system-prompt",
    path: "/inapintar/master-system-prompt",
    meta: indexQ5lZFULKUiMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/master-system-prompt/index.vue")
  },
  {
    name: "inapintar-score-detail-id",
    path: "/inapintar/score-detail/:id()",
    meta: indexFcQrcZgs4KMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/score-detail/[id]/index.vue")
  },
  {
    name: "inapintar-score-id",
    path: "/inapintar/score/:id()",
    meta: indexApM9Eo5HfyMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/score/[id]/index.vue")
  },
  {
    name: "inapintar-score",
    path: "/inapintar/score",
    meta: indexD0SYZiYjMYMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/score/index.vue")
  },
  {
    name: "inapintar-scoring-chat-id",
    path: "/inapintar/scoring-chat/:id()",
    meta: indexgQwQXolTZAMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/scoring-chat/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-chat",
    path: "/inapintar/scoring-chat",
    meta: indexqpbe8c0wYdMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/scoring-chat/index.vue")
  },
  {
    name: "inapintar-scoring-question-id",
    path: "/inapintar/scoring-question/:id()",
    meta: indexTSJ4xsi6okMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/scoring-question/[id]/index.vue")
  },
  {
    name: "inapintar-scoring-question",
    path: "/inapintar/scoring-question",
    meta: indexT6IoaMV0CLMeta || {},
    component: () => import("/vercel/path0/layers/inapintar/pages/inapintar/scoring-question/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: indexJSjF1neT4kMeta?.name,
    path: "/marketing/:category()/:id()",
    meta: indexJSjF1neT4kMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index.vue"),
    children: [
  {
    name: "marketing-category-id-index-address",
    path: "address",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/address.vue")
  },
  {
    name: "marketing-category-id-index-contacts",
    path: "contacts",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/contacts.vue")
  },
  {
    name: "marketing-category-id-index-files",
    path: "files",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/files.vue")
  },
  {
    name: "marketing-category-id-index-financial",
    path: "financial",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/financial.vue")
  },
  {
    name: "marketing-category-id-index",
    path: "",
    meta: indexx9vOwpr4T8Meta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/index.vue")
  },
  {
    name: "marketing-category-id-index-invoices",
    path: "invoices",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/invoices.vue")
  },
  {
    name: "marketing-category-id-index-notes",
    path: "notes",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/notes.vue")
  },
  {
    name: "marketing-category-id-index-projects-status",
    path: "projects/:status()",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/projects/[status]/index.vue")
  },
  {
    name: "marketing-category-id-index-questioner",
    path: "questioner",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/[id]/index/questioner.vue")
  }
]
  },
  {
    name: "marketing-category",
    path: "/marketing/:category()",
    meta: index6pfi4qnfKsMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/[category]/index.vue")
  },
  {
    name: "marketing-dashboard",
    path: "/marketing/dashboard",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/dashboard/index.vue")
  },
  {
    name: indexNdFkmUSxoGMeta?.name,
    path: "/marketing/sales/:id()",
    meta: indexNdFkmUSxoGMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index.vue"),
    children: [
  {
    name: "marketing-sales-id-index-clients",
    path: "clients",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/clients.vue")
  },
  {
    name: "marketing-sales-id-index-fees",
    path: "fees",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/fees.vue")
  },
  {
    name: "marketing-sales-id-index",
    path: "",
    meta: indexDodHIhW9GaMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/index.vue")
  },
  {
    name: "marketing-sales-id-index-projects-status",
    path: "projects/:status()",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/[id]/index/projects/[status]/index.vue")
  }
]
  },
  {
    name: "marketing-sales",
    path: "/marketing/sales",
    meta: indextQS8bWQYT4Meta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/sales/index.vue")
  },
  {
    name: "marketing-seminar-create",
    path: "/marketing/seminar/create",
    meta: createkWpFYMg9JVMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/create.vue")
  },
  {
    name: index1ovMG8JtOqMeta?.name,
    path: "/marketing/seminar/detail/:id()",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index.vue"),
    children: [
  {
    name: "marketing-seminar-detail-id-index-additional",
    path: "additional",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/additional.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-email-marketing",
    path: "email-marketing",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/email-marketing.vue")
  },
  {
    name: "marketing-seminar-detail-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/index.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-invoices",
    path: "invoices",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/invoices.vue")
  },
  {
    name: "marketing-seminar-detail-id-index-participant",
    path: "participant",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/detail/[id]/index/participant.vue")
  }
]
  },
  {
    name: "marketing-seminar",
    path: "/marketing/seminar",
    meta: indexAyJjh5izT8Meta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/index.vue")
  },
  {
    name: "marketing-seminar-update-id",
    path: "/marketing/seminar/update/:id()",
    meta: indexDWXSQaGRpaMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/seminar/update/[id]/index.vue")
  },
  {
    name: "marketing-speaker-host-create",
    path: "/marketing/speaker-host/create",
    meta: create74TaHLSD5jMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/speaker-host/create.vue")
  },
  {
    name: "marketing-speaker-host",
    path: "/marketing/speaker-host",
    meta: indexceZSXhxXCdMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/speaker-host/index.vue")
  },
  {
    name: "marketing-speaker-host-update-id",
    path: "/marketing/speaker-host/update/:id()",
    meta: indexZ0TZ8VJJDDMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/speaker-host/update/[id]/index.vue")
  },
  {
    name: indexu97gOFua18Meta?.name,
    path: "/marketing/template-questioner/:id()",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index.vue"),
    children: [
  {
    name: "marketing-template-questioner-id-index-create",
    path: "create",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/create.vue")
  },
  {
    name: "marketing-template-questioner-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/index.vue")
  },
  {
    name: "marketing-template-questioner-id-index-update",
    path: "update",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/[id]/index/update.vue")
  }
]
  },
  {
    name: "marketing-template-questioner",
    path: "/marketing/template-questioner",
    meta: index7x9EfFlvAzMeta || {},
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/index.vue")
  },
  {
    name: "marketing-template-questioner-list-update-id",
    path: "/marketing/template-questioner/list/update/:id()",
    component: () => import("/vercel/path0/layers/marketing/pages/marketing/template-questioner/list/update/[id].vue")
  },
  {
    name: "master-bank",
    path: "/master/bank",
    meta: indexHKe0OUOQn2Meta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/bank/index.vue")
  },
  {
    name: "master-city",
    path: "/master/city",
    meta: indexQMxl1ukjMUMeta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/city/index.vue")
  },
  {
    name: "master-country",
    path: "/master/country",
    meta: indexgt0Wy2qGHfMeta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/country/index.vue")
  },
  {
    name: "master-currency",
    path: "/master/currency",
    meta: indexH4MnnuS4UYMeta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/currency/index.vue")
  },
  {
    name: "master-province",
    path: "/master/province",
    meta: indexN3ZzSP0CezMeta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/province/index.vue")
  },
  {
    name: "master-questioner",
    path: "/master/questioner",
    meta: indexizRD03NWTHMeta || {},
    component: () => import("/vercel/path0/layers/master/pages/master/questioner/index.vue")
  },
  {
    name: "media-manager",
    path: "/media-manager",
    meta: index04MMBqA3XCMeta || {},
    component: () => import("/vercel/path0/pages/media-manager/index.vue")
  },
  {
    name: "meeting",
    path: "/meeting",
    meta: index9tywSo2bTXMeta || {},
    component: () => import("/vercel/path0/pages/meeting/index.vue")
  },
  {
    name: "monitoring-consultation",
    path: "/monitoring/consultation",
    component: () => import("/vercel/path0/pages/monitoring/consultation/index.vue")
  },
  {
    name: "monitoring-project",
    path: "/monitoring/project",
    component: () => import("/vercel/path0/pages/monitoring/project/index.vue")
  },
  {
    name: "overtime-letter",
    path: "/overtime-letter",
    meta: indexRqftDruit4Meta || {},
    component: () => import("/vercel/path0/layers/humanResource/pages/overtime-letter/index.vue")
  },
  {
    name: "partner-account",
    path: "/partner/account",
    meta: indexd2FeQpPfDBMeta || {},
    component: () => import("/vercel/path0/pages/partner/account/index.vue"),
    children: [
  {
    name: "partner-account-index-profile",
    path: "profile",
    meta: profileoIx9pgJ2PKMeta || {},
    component: () => import("/vercel/path0/pages/partner/account/index/profile.vue")
  },
  {
    name: "partner-account-index-security",
    path: "security",
    component: () => import("/vercel/path0/pages/partner/account/index/security.vue")
  }
]
  },
  {
    name: indexg5IFpVOSyXMeta?.name,
    path: "/partner/client/:id()",
    meta: indexg5IFpVOSyXMeta || {},
    component: () => import("/vercel/path0/pages/partner/client/[id]/index.vue"),
    children: [
  {
    name: "partner-client-id-index",
    path: "",
    component: () => import("/vercel/path0/pages/partner/client/[id]/index/index.vue")
  },
  {
    name: "partner-client-id-index-invoice",
    path: "invoice",
    component: () => import("/vercel/path0/pages/partner/client/[id]/index/invoice.vue")
  },
  {
    name: "partner-client-id-index-project",
    path: "project",
    component: () => import("/vercel/path0/pages/partner/client/[id]/index/project.vue")
  }
]
  },
  {
    name: "partner-client",
    path: "/partner/client",
    meta: indexnLr3mDcCKVMeta || {},
    component: () => import("/vercel/path0/pages/partner/client/index.vue")
  },
  {
    name: "partner",
    path: "/partner",
    meta: indexD7Gcj2MXxEMeta || {},
    component: () => import("/vercel/path0/pages/partner/index.vue")
  },
  {
    name: "partner-invoice-id",
    path: "/partner/invoice/:id()",
    meta: indexM3aBDkJLgDMeta || {},
    component: () => import("/vercel/path0/pages/partner/invoice/[id]/index.vue")
  },
  {
    name: "partner-invoice",
    path: "/partner/invoice",
    meta: indexBtLjKLIQX5Meta || {},
    component: () => import("/vercel/path0/pages/partner/invoice/index.vue")
  },
  {
    name: indexCaEXjKD66SMeta?.name,
    path: "/partner/project/:id()",
    meta: indexCaEXjKD66SMeta || {},
    component: () => import("/vercel/path0/pages/partner/project/[id]/index.vue"),
    children: [
  {
    name: "partner-project-id-index",
    path: "",
    component: () => import("/vercel/path0/pages/partner/project/[id]/index/index.vue")
  },
  {
    name: "partner-project-id-index-invoice",
    path: "invoice",
    component: () => import("/vercel/path0/pages/partner/project/[id]/index/invoice.vue")
  }
]
  },
  {
    name: "partner-project",
    path: "/partner/project",
    meta: indexNFiFlpaguuMeta || {},
    component: () => import("/vercel/path0/pages/partner/project/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "project-create",
    path: "/project/create",
    meta: createTf8mfihtdZMeta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/create.vue")
  },
  {
    name: indexa2wRAFx5S7Meta?.name,
    path: "/project/dashboard",
    meta: indexa2wRAFx5S7Meta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index.vue"),
    children: [
  {
    name: "project-dashboard-index-assignment",
    path: "assignment",
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index/assignment.vue")
  },
  {
    name: "project-dashboard-index-change-manager",
    path: "change-manager",
    meta: change_45managerCe02WfBViOMeta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index/change-manager.vue")
  },
  {
    name: "project-dashboard-index",
    path: "",
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index/index.vue")
  },
  {
    name: "project-dashboard-index-review",
    path: "review",
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index/review.vue")
  },
  {
    name: "project-dashboard-index-term-of-payment",
    path: "term-of-payment",
    meta: term_45of_45paymentA6X04lxbiLMeta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/dashboard/index/term-of-payment.vue")
  }
]
  },
  {
    name: indexZnXS3BRoz1Meta?.name,
    path: "/project/detail/:id()",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index.vue"),
    children: [
  {
    name: contract6BwRPALHpkMeta?.name,
    path: "contract",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/contract.vue"),
    children: [
  {
    name: "project-detail-id-index-contract-files",
    path: "files",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/contract/files.vue")
  },
  {
    name: "project-detail-id-index-contract",
    path: "",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/contract/index.vue")
  }
]
  },
  {
    name: "project-detail-id-index-direct-assignment",
    path: "direct-assignment",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/direct-assignment.vue")
  },
  {
    name: "project-detail-id-index-due-date-history",
    path: "due-date-history",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/due-date-history.vue")
  },
  {
    name: "project-detail-id-index-estimate-cost",
    path: "estimate-cost",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/estimate-cost.vue")
  },
  {
    name: "project-detail-id-index-estimate-timer",
    path: "estimate-timer",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/estimate-timer.vue")
  },
  {
    name: "project-detail-id-index-external-issue",
    path: "external-issue",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/external-issue.vue")
  },
  {
    name: "project-detail-id-index-files",
    path: "files",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/files.vue")
  },
  {
    name: "project-detail-id-index-follow-up",
    path: "follow-up",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/follow-up.vue")
  },
  {
    name: "project-detail-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/index.vue")
  },
  {
    name: "project-detail-id-index-internal-issue",
    path: "internal-issue",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/internal-issue.vue")
  },
  {
    name: "project-detail-id-index-invoice",
    path: "invoice",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/invoice.vue")
  },
  {
    name: "project-detail-id-index-logs",
    path: "logs",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/logs.vue")
  },
  {
    name: "project-detail-id-index-milestone",
    path: "milestone",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/milestone.vue")
  },
  {
    name: "project-detail-id-index-project-fee",
    path: "project-fee",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/project-fee.vue")
  },
  {
    name: "project-detail-id-index-realization",
    path: "realization",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/realization.vue")
  },
  {
    name: "project-detail-id-index-teams",
    path: "teams",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/teams.vue")
  },
  {
    name: "project-detail-id-index-term-of-payment",
    path: "term-of-payment",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/term-of-payment.vue")
  },
  {
    name: "project-detail-id-index-timesheet",
    path: "timesheet",
    component: () => import("/vercel/path0/layers/project/pages/project/detail/[id]/index/timesheet.vue")
  }
]
  },
  {
    name: "project",
    path: "/project",
    component: () => import("/vercel/path0/layers/project/pages/project/index.vue")
  },
  {
    name: "project-listing-category",
    path: "/project/listing/:category()",
    component: () => import("/vercel/path0/layers/project/pages/project/listing/[category]/index.vue")
  },
  {
    name: "project-recreate-id",
    path: "/project/recreate/:id()",
    meta: index0jpntMicg0Meta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/recreate/[id]/index.vue")
  },
  {
    name: "project-update-id-contract",
    path: "/project/update/:id()/contract",
    meta: contractnbMt0oe3LOMeta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/update/[id]/contract.vue")
  },
  {
    name: "project-update-id",
    path: "/project/update/:id()",
    meta: indexDrdWyYfjaOMeta || {},
    component: () => import("/vercel/path0/layers/project/pages/project/update/[id]/index.vue")
  },
  {
    name: indexrqIzlOooyxMeta?.name,
    path: "/report/monthly/:id()",
    meta: indexrqIzlOooyxMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index.vue"),
    children: [
  {
    name: "report-monthly-id-index-asset",
    path: "asset",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/asset.vue")
  },
  {
    name: "report-monthly-id-index-client",
    path: "client",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/client.vue")
  },
  {
    name: "report-monthly-id-index-employment-status",
    path: "employment-status",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/employment-status.vue")
  },
  {
    name: "report-monthly-id-index-financial-ratio",
    path: "financial-ratio",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/financial-ratio.vue")
  },
  {
    name: "report-monthly-id-index-financial-statement",
    path: "financial-statement",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/financial-statement.vue")
  },
  {
    name: "report-monthly-id-index-human-resource",
    path: "human-resource",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/human-resource.vue")
  },
  {
    name: "report-monthly-id-index",
    path: "",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/index.vue")
  },
  {
    name: "report-monthly-id-index-profit-loss",
    path: "profit-loss",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/profit-loss.vue")
  },
  {
    name: "report-monthly-id-index-project",
    path: "project",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/project.vue")
  },
  {
    name: "report-monthly-id-index-quotation-report",
    path: "quotation-report",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/quotation-report.vue")
  },
  {
    name: "report-monthly-id-index-source-client",
    path: "source-client",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/source-client.vue")
  },
  {
    name: "report-monthly-id-index-workload",
    path: "workload",
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/[id]/index/workload.vue")
  }
]
  },
  {
    name: "report-monthly-create",
    path: "/report/monthly/create",
    meta: create4D1FkUYegJMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/create.vue")
  },
  {
    name: "report-monthly",
    path: "/report/monthly",
    meta: indexesfodUGKTCMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/monthly/index.vue")
  },
  {
    name: "report-potential-invoice",
    path: "/report/potential-invoice",
    meta: indexOmGUZ5hgCQMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/potential-invoice/index.vue")
  },
  {
    name: "report-progress-project",
    path: "/report/progress-project",
    meta: indexCKjeomZ5jAMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/progress-project/index.vue")
  },
  {
    name: "report-project-marginal-margin",
    path: "/report/project-marginal-margin",
    meta: indexN3f45DUXBMMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/project-marginal-margin/index.vue")
  },
  {
    name: "report-project-per-status",
    path: "/report/project-per-status",
    meta: index6xglOYSlCyMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/project-per-status/index.vue")
  },
  {
    name: "report-timer-consultant-per-project",
    path: "/report/timer-consultant-per-project",
    meta: indexL3Y6mPcCThMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/timer-consultant-per-project/index.vue")
  },
  {
    name: "report-timer-consultant",
    path: "/report/timer-consultant",
    meta: indexDzlF2W9VEUMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/timer-consultant/index.vue")
  },
  {
    name: "report-timesheet",
    path: "/report/timesheet",
    meta: index2dZDlis0vIMeta || {},
    component: () => import("/vercel/path0/layers/reports/pages/report/timesheet/index.vue")
  },
  {
    name: "restricted",
    path: "/restricted",
    meta: indexr5nZMZEa5EMeta || {},
    component: () => import("/vercel/path0/pages/restricted/index.vue")
  },
  {
    name: "sleekflow-contact-list",
    path: "/sleekflow/contact-list",
    component: () => import("/vercel/path0/pages/sleekflow/contact-list.vue")
  },
  {
    name: "support-knowledge-base",
    path: "/support/knowledge-base",
    meta: indexAR77pnRrkYMeta || {},
    component: () => import("/vercel/path0/pages/support/knowledge-base/index.vue")
  },
  {
    name: "support-package",
    path: "/support/package",
    meta: indexNMrlSjZCLjMeta || {},
    component: () => import("/vercel/path0/pages/support/package/index.vue")
  },
  {
    name: "support-package-send-document",
    path: "/support/package/send-document",
    component: () => import("/vercel/path0/pages/support/package/send-document/index.vue")
  },
  {
    name: "support-package-track-id",
    path: "/support/package/track/:id()",
    component: () => import("/vercel/path0/pages/support/package/track/[id].vue")
  },
  {
    name: "support-ticket",
    path: "/support/ticket",
    meta: indexStGafQtT1bMeta || {},
    component: () => import("/vercel/path0/pages/support/ticket/index.vue")
  },
  {
    name: "tax-calculation-calculator-pph-21",
    path: "/tax-calculation/calculator-pph-21",
    meta: indextsk0OLeaOyMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/calculator-pph-21/index.vue")
  },
  {
    name: "tax-calculation-excel-template-id",
    path: "/tax-calculation/excel-template/:id()",
    meta: indexT52VYNhMdPMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/[id]/index.vue")
  },
  {
    name: "tax-calculation-excel-template-edit",
    path: "/tax-calculation/excel-template/edit",
    meta: editzxl4nRH3HeMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/edit.vue")
  },
  {
    name: "tax-calculation-excel-template",
    path: "/tax-calculation/excel-template",
    meta: indexH21zIpWo1iMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/excel-template/index.vue")
  },
  {
    name: "tax-calculation-upload-excel-id",
    path: "/tax-calculation/upload-excel/:id()",
    meta: indexxpfXR0RnXQMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/upload-excel/[id]/index.vue")
  },
  {
    name: "tax-calculation-worksheet-id",
    path: "/tax-calculation/worksheet/:id()",
    meta: index8CVO4B1r5ZMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/worksheet/[id]/index.vue")
  },
  {
    name: "tax-calculation-worksheet",
    path: "/tax-calculation/worksheet",
    meta: indexRyxdXqLMeVMeta || {},
    component: () => import("/vercel/path0/layers/taxCalculation/pages/tax-calculation/worksheet/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexOXtObJcgq9Meta || {},
    component: () => import("/vercel/path0/pages/users/index.vue")
  },
  {
    name: "website-expert-id",
    path: "/website/expert/:id()",
    meta: indexgup4K4QveqMeta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/expert/[id]/index.vue")
  },
  {
    name: "website-expert-create",
    path: "/website/expert/create",
    meta: createn7ExI1djO6Meta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/expert/create.vue")
  },
  {
    name: "website-expert",
    path: "/website/expert",
    meta: indexqo5oO9GXm9Meta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/expert/index.vue")
  },
  {
    name: "website-post-id",
    path: "/website/post/:id()",
    meta: indexnYWoC4QaN4Meta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/post/[id]/index.vue")
  },
  {
    name: "website-post-create",
    path: "/website/post/create",
    meta: create3zfnlnSsuJMeta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/post/create.vue")
  },
  {
    name: "website-post",
    path: "/website/post",
    meta: indexs2UY5jlpJbMeta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/post/index.vue")
  },
  {
    name: "website-service-id",
    path: "/website/service/:id()",
    meta: indexFCiOXLaLnFMeta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/service/[id]/index.vue")
  },
  {
    name: "website-service-create",
    path: "/website/service/create",
    meta: createvyNYGIPH0dMeta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/service/create.vue")
  },
  {
    name: "website-service",
    path: "/website/service",
    meta: index3VfcVhOG55Meta || {},
    component: () => import("/vercel/path0/layers/website/pages/website/service/index.vue")
  },
  {
    name: "widget-pajakumkm-form-checkout",
    path: "/widget/pajakumkm/form-checkout",
    meta: form_45checkoutG91ZMzAjsyMeta || {},
    component: () => import("/vercel/path0/pages/widget/pajakumkm/form-checkout.vue")
  },
  {
    name: "widget-pajakumkm-pay-id",
    path: "/widget/pajakumkm/pay/:id()",
    meta: indexyjcviuQ6VOMeta || {},
    component: () => import("/vercel/path0/pages/widget/pajakumkm/pay/[id]/index.vue")
  }
]